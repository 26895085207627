







































import { Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import PayeesTableComponent from '../components/PayeesTableComponent.vue';
import PayeeComponent from '../components/PayeeComponent.vue';
import ExpenseComponent from '../components/ExpenseComponent.vue';
import { VendorCategory } from '../components/PayeeHelper';
import DialogComponent from '../components/DialogComponent.vue';

import * as _ from 'lodash';

@Component({
  components: {
    PayeesTableComponent,
    PayeeComponent,
    ExpenseComponent,
    DialogComponent,
  },
})
export default class PayeesVue extends BaseComponent {
  protected baseRoute: string = '/payees';
  private category = VendorCategory.Payee;
  private bAddVendor: boolean = false;
  private bAddExpense: boolean = false;
  private editedVendorId: string = '';
  private updateVendor: number = 0;
  private updateExpense: number = 0;

  protected mounted() {
    this.mount();
  }

  protected newVendorItem() {
    this.editedVendorId = '';
    this.bAddVendor = true;
  }

  protected newExpenseItem() {
    this.bAddExpense = true;
  }

  private mount() {
    if (this.$route.path.endsWith('new-payee')) {
      this.newVendorItem();
    }
    if (this.$route.path.endsWith('new-expense')) {
      this.newExpenseItem();
    }
  }

  private navigate(code) {
    switch (code) {
      case 'payee':
        this.changeRoute('/payees/new-payee', true);
        this.newVendorItem();
        break;
      case 'payee-expense':
        this.changeRoute('/payees/new-expense', true);
        this.newExpenseItem();
        break;
    }
  }

  private saveVendor(id) {
    this.updateVendor++;
  }

  private saveExpense(id) {
    this.updateExpense++;
  }
}
